<template>
  <div>
    <Modal :options="{width: '50vw',close: true}" @close="cancelar">
      <form @submit.prevent="guardar_config">
        <div class="title">Configuración</div>
        <div class="body">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
            <div class="col-sm-10"><input v-model="configuracion.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
            <div class="col-sm-10"><input v-model="configuracion.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="configuracion" class="col-form-label col-sm-2">Configuración</label>
            <div class="col-sm-10"><input v-model="configuracion.configuracion" type="text" name="configuracion" id="configuracion" class="form-control"></div>
          </div>
        </div>
        <div class="footer" v-if="$auth.can('metricas','config_editar')">
          <div class="row">
            <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn">Guardar</button></div>
            <div class="col-sm-2"><button class="btn danger-btn" type="button" @click="cancelar">Cancelar</button></div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/metricas/api/configuracion'

  export default {
    components: {
      Modal
    }
    ,props: {
      config: {
        type: Object
        ,default: function() {
          return {
            nombre: null
            ,descripcion: null
            ,configuracion: null
          }
        }
      }
    }
    ,mounted: function() {
      this.configuracion = this.config;
    }
    ,data: function() {
      return {
        configuracion:{
          nombre: null
          ,descripcion: null
          ,configuracion: null
        }
      }
    }
    ,methods: {
      cancelar: function() {
        this.$emit('close')
      }
      ,guardar_config: async function() {
        try {
          if (!this.configuracion.id) 
            await api.crear_config(this.configuracion);
          else
            await api.editar_config(this.configuracion.id, this.configuracion);

          this.cancelar();
        }catch(e) {
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>