<template>
  <div>
    <div class="row mt-4 mb-4">
      <h1 class="col-sm-12 text-right">Configuración</h1>
    </div>

    <Datagrid :data="configuracion" :config="dataGridConfig" :select="seleccionar" @actions="dataGridActions">
      <div class="row">
        <div class="col-sm-2"><button class="btn warning-btn" @click="obtener_configuracion">Recargar</button></div>
        <div class="col-sm-2"><button class="btn secondary-btn" @click="revirsar_configuracion">Revisar configuración</button></div>
        <div v-if="$auth.can('metricas','config_crear')" class="col-sm-2"><button class="btn principal-btn" @click="modal_editar=true">Crear configuración</button></div>
      </div>
    </Datagrid>

    <Editar v-if="modal_editar" :config="config" @close="cerrar_modal" />
  </div>
</template>

<script type="text/javascript">
  import Datagrid from '@/components/DataGridV2'
  import Editar from './Configuracion/Editar'

  import api from '@/apps/metricas/api/configuracion'

  export default {
    components: {
      Datagrid, Editar
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'configuraciones'
          ,cols: {
            nombre: 'Nombre'
            ,descripcion: {
              text: 'Descripción'
              ,ordeable: false
            }
            ,configuracion: {
              text: 'Configuración'
              ,ordeable: false
            }
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,configuracion: []
        ,modal_editar: false
        ,config: {
          nombre: null
          ,descripcion: null
          ,valor: null
        }
      }
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        if (tipo == 'options') {
          this.options = data;
          this.obtener_configuracion();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_configuracion: async function() {
        try {
          let res = (await api.obtener_configuraciones(this.options)).data;

          this.configuracion = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.current_page;
          this.dataGridConfig.paginator.total_registros = res.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,revirsar_configuracion: function() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar la configuración a editar','error','alert');

        this.config = this.seleccionadas[0];

        this.modal_editar = true;
      }
      ,cerrar_modal: function() {
        this.modal_editar = false;
        this.seleccionar = false;

        this.config = {
          nombre: null
          ,descripcion: null
          ,valor: null
        }

        this.obtener_configuracion();
      }
    }
  }
</script>